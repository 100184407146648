const interpersonal_skills = [
    "Leadership",
    "Time Management",
    "Teamwork",
    "Communication",
    "Organization",
    "Growth Mindset",
    "Desire to Learn",
    "Embrace Challenges",
];
export default interpersonal_skills;
